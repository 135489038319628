import React from 'react';
import BhajansWebsite from './components/BhajansWebsite';

function App() {
  return (
    <div className="App">
      <BhajansWebsite />
    </div>
  );
}

export default App;
