import React, { useState, useRef, useEffect, createContext, useContext } from 'react';
import {
  Play, Pause, SkipBack, SkipForward, Volume2, VolumeX, Search,
  Maximize2, ListMusic, Info, Repeat, Shuffle, X, Loader2
} from 'lucide-react';
import * as GA from './googleanalytics';

import { motion } from 'framer-motion';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { WhatsappShareButton, WhatsappIcon, FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon } from 'react-share';
//import { useLocation } from 'react-router-dom';
import { auth, googleProvider } from './firebase';
//import usePageTracking from './usePageTracking';
import { useLocation } from 'react-router-dom';

const ThemeContext = createContext();

const themes = {
  default: {
    primary: '#f97316',
    secondary: '#3b82f6',
    background: '#fffbeb',
    text: '#1f2937',
  },
  dark: {
    primary: '#f59e0b',
    secondary: '#60a5fa',
    background: '#1f2937',
    text: '#f3f4f6',
  },
  nature: {
    primary: '#10b981',
    secondary: '#3b82f6',
    background: '#ecfdf5',
    text: '#1f2937',
  },
};

const ThemeProvider = ({ children }) => {
  const [currentTheme, setCurrentTheme] = useState('default');

  useEffect(() => {
    document.documentElement.style.setProperty('--color-primary', themes[currentTheme].primary);
    document.documentElement.style.setProperty('--color-secondary', themes[currentTheme].secondary);
    document.documentElement.style.setProperty('--color-background', themes[currentTheme].background);
    document.documentElement.style.setProperty('--color-text', themes[currentTheme].text);
  }, [currentTheme]);

  return (
    <ThemeContext.Provider value={{ currentTheme, setCurrentTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

const ThemeSwitcher = () => {
  const { currentTheme, setCurrentTheme } = useContext(ThemeContext);

  return (
    <div className="flex space-x-2">
      {Object.keys(themes).map((theme) => (
        <button
          key={theme}
          onClick={() => setCurrentTheme(theme)}
          className={`w-8 h-8 rounded-full ${currentTheme === theme ? 'ring-2 ring-offset-2 ring-gray-400' : ''}`}
          style={{ backgroundColor: themes[theme].primary }}
        ></button>
      ))}
    </div>
  );
};

const isMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

// Add this new component
const AudioVisualizer = ({ audioRef }) => {
  const canvasRef = useRef(null);
  const [isMobileDevice, setIsMobileDevice] = useState(false);

  useEffect(() => {
    setIsMobileDevice(isMobile());
  }, []);

  useEffect(() => {
    if (isMobileDevice || !audioRef.current) return;

    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const analyser = audioContext.createAnalyser();
    const source = audioContext.createMediaElementSource(audioRef.current);
    source.connect(analyser);
    analyser.connect(audioContext.destination);

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrameId;

    const draw = () => {
      const bufferLength = analyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);
      analyser.getByteFrequencyData(dataArray);

      ctx.clearRect(0, 0, canvas.width, canvas.height);

      const barWidth = (canvas.width / bufferLength) * 2.5;
      let x = 0;

      for (let i = 0; i < bufferLength; i++) {
        const barHeight = dataArray[i] / 2;
        ctx.fillStyle = `rgb(${barHeight + 100}, 50, 50)`;
        ctx.fillRect(x, canvas.height - barHeight, barWidth, barHeight);
        x += barWidth + 1;
      }

      animationFrameId = requestAnimationFrame(draw);
    };

    draw();

    return () => {
      cancelAnimationFrame(animationFrameId);
      source.disconnect();
    };
  }, [audioRef, isMobileDevice]);

  if (isMobileDevice) {
    return null; // Don't render anything on mobile
  }

  return <canvas ref={canvasRef} className="w-full h-24 md:h-32" />;
};

const formatDuration = (duration) => {
  if (isNaN(duration) || duration <= 0) return '0:00';
  const minutes = Math.floor(duration / 60);
  const seconds = Math.floor(duration % 60);
  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};

const formatTime = (time) => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};

const toCamelCase = (str) => {
  return str
    .toLowerCase()
    .replace(/_/g, ' ') // Replace underscores with spaces
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
    .replace(/ /g, ' '); // Keep spaces
};

const maskEmail = (email) => {
  if (!email) return '';

  const [localPart, domain] = email.split('@');
  const maskedLocalPart = `${localPart.slice(0, 2)}***${localPart.slice(-1)}`;
  const domainParts = domain.split('.');
  const maskedDomain = `${domainParts[0][0]}***`;

  return `${maskedLocalPart}@${maskedDomain}.${domainParts.slice(1).join('.')}`;
};


const formatBhajanName = (name) => {
  //console.log('Original bhajan name:', name); // Log original name
  const firstBracketIndex = name.indexOf('(');
  if (firstBracketIndex === -1) {
    const camelCaseName = toCamelCase(name);
    //console.log('Camel case bhajan name (no brackets):', camelCaseName); // Log transformed name
    return camelCaseName;
  }
  const mainName = name.substring(0, firstBracketIndex).trim();
  const rest = name.substring(firstBracketIndex);
  const formattedName = `${toCamelCase(mainName)} ${rest}`;
  //console.log('Camel case bhajan name (with brackets):', formattedName); // Log transformed name
  return formattedName;
};





const BhajansWebsite = () => {
  const location = useLocation();
  //console.log('Current location:', location);
  const [userTracks, setUserTracks] = useState(0);
  const [isProgressDialogOpen, setIsProgressDialogOpen] = useState(false);
  const [playAfterInteraction, setPlayAfterInteraction] = useState(false); // Add this state
  const [user, setUser] = useState(null);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [volume, setVolume] = useState(50);
  const [currentTrack, setCurrentTrack] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [tracks, setTracks] = useState([]);
  const [showTracks, setShowTracks] = useState(true);
  const [showOptions, setShowOptions] = useState(false);
  const [language, setLanguage] = useState('EN');
  const [generatedTrack, setGeneratedTrack] = useState(null);
  const [selectedStyles, setSelectedStyles] = useState([]);
  const [bhajanDescription, setBhajanDescription] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [generationMessage, setGenerationMessage] = useState('');
  const [generationProgress, setGenerationProgress] = useState(0);
  const audioRef = useRef(null);
  const [repeat, setRepeat] = useState(false);
  const [shuffle, setShuffle] = useState(false);
  const [selectedBhajan, setSelectedBhajan] = useState('');
  const [selectedGenre, setSelectedGenre] = useState('');
  const [selectedInstrument, setSelectedInstrument] = useState('');
  const [showInfo, setShowInfo] = useState(false);
  const canvasRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [likes, setLikes] = useState(0);
  const [views, setViews] = useState(0);
  const scrollRefs = useRef([]);
  const [expanded, setExpanded] = useState({});
  const [expandedTracks, setExpandedTracks] = useState({});
  const [totalPlayedTime, setTotalPlayedTime] = useState(0);
  const [finalMessage, setFinalMessage] = useState('');
  const [bpm, setBpm] = useState(80);
  const [selectedVocals, setVocals] = useState('Male');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
const [generatedTrackUrl, setGeneratedTrackUrl] = useState('');
const [generatedTrackUrl2, setGeneratedTrackUrl2] = useState('');
const [creationDuration, setcreationDuration] = useState('');
const [generatedTrackName1, setGeneratedTrackName1] = useState('');
const [generatedTrackName2, setGeneratedTrackName2] = useState('');
const [generatedTrackGenre1, setGeneratedTrackGenre1] = useState('');
const [generatedTrackGenre2, setGeneratedTrackGenre2] = useState('');
const [generatedTrackInstruments1, setGeneratedTrackInstruments1] = useState('');
const [generatedTrackInstruments2, setGeneratedTrackInstruments2] = useState('');
const [totalViews, setTotalViews] = useState(0);
const [topSongs, setTopSongs] = useState([]);
const [quotaExceeded, setQuotaExceeded] = useState(false);

const QuotaExceededMessage = ({ onClose }) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full text-center">
      <h3 className="text-2xl font-bold mb-4 text-red-600">Daily Quota Exceeded</h3>
      <p className="mb-6 text-gray-700">
        You've reached your daily limit for creating bhajans. Please try again tomorrow!
      </p>
      <button
        onClick={onClose}
        className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-300"
      >
        Close
      </button>
    </div>
  </div>
);


const handleSkipBack = () => {
  setCurrentTrack((prev) => {
    const newTrack = (prev - 1 + filteredTracks.length) % filteredTracks.length;
    scrollToTrack(newTrack);
    return newTrack;
  });
};

const handleSkipForward = () => {
  setCurrentTrack((prev) => {
    const newTrack = (prev + 1) % filteredTracks.length;
    scrollToTrack(newTrack);
    return newTrack;
  });
};


const scrollToTrack = (index) => {
  const container = document.getElementById('playlist-container');
  const trackElement = scrollRefs.current[index];
  
  if (container && trackElement) {
    const containerRect = container.getBoundingClientRect();
    const trackRect = trackElement.getBoundingClientRect();
    
    if (trackRect.top < containerRect.top || trackRect.bottom > containerRect.bottom) {
      container.scrollTop = trackElement.offsetTop - container.offsetTop;
    }
  }
};

useEffect(() => {
  scrollToTrack(currentTrack);
}, [currentTrack]);


useEffect(() => {
  GA.pageview(location.pathname + location.search);
}, [location]);


useEffect(() => {
  const fetchTopSongs = async () => {
    try {
      const response = await fetch('/api/top-favorite-songs');
      const data = await response.json();
      console.log(data);
      setTopSongs(data);
    } catch (error) {
      console.error('Error fetching top favorite songs:', error);
    }
  };

  fetchTopSongs();
}, []);


const toggleTrackInfo = (index) => {
  setExpandedTracks((prevState) => ({
    ...prevState,
    [index]: !prevState[index],
  }));
};



useEffect(() => {
  if (scrollRefs.current[currentTrack]) {
    scrollRefs.current[currentTrack].scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start',
      scrollMode: 'if-needed'
    });
  }
}, [currentTrack]);


const renderTrack = (track, index) => (
  <div key={track.id} className="track-item">
    <div
      className={`track-header ${currentTrack === index ? 'active' : ''}`}
      onClick={() => setCurrentTrack(index)}
    >
      <span>{track.index + 1}. {formatBhajanName(track.bhajan_name)}</span>
      <button onClick={(e) => { e.stopPropagation(); setExpanded(expanded === index ? null : index); }}>
        {expanded === index ? 'Collapse' : 'Expand'}
      </button>
    </div>
    {expanded === index && (
      <div className="track-details">
        <p>Genre: {track.genre}</p>
        <p>Instruments: {track.instruments}</p>
        <p>Vocals: {track.vocals}</p>
        <p>BPM: {track.bpm}</p>
        <p>Created By: {track.user_name} ({maskEmail(track.user_email)})</p>
        <p>Created Date: {track.created_at ? new Date(track.created_at).toLocaleString() : 'N/A'}</p>
      </div>
    )}
  </div>
);

const fetchUserTracks = async (userEmail) => {
  try {
    const response = await fetch(`/api/user-tracks?email=${userEmail}`);
    const data = await response.json();
    setUserTracks(data.totalTracks);
  } catch (error) {
    console.error('Error fetching user tracks:', error);
  }
};

useEffect(() => {
  if (user) {
    fetchUserTracks(user.email);
  }
}, [user]);



const fetchTotalViews = async () => {
  try {
    const response = await fetch('/api/total-views');
    const data = await response.json();
    setTotalViews(data.totalViews);
  } catch (error) {
    console.error('Error fetching total views:', error);
  }
};

useEffect(() => {
  fetchTotalViews();
}, []);



useEffect(() => {
  const unsubscribe = auth.onAuthStateChanged((user) => {
    setUser(user);
  });
  return () => unsubscribe();
}, []);

const handleLogin = async () => {
  try {
    setIsLoggingIn(true);
    const result = await auth.signInWithPopup(googleProvider);
    setUser(result.user);

    // Track login event
    GA.event({
      action: 'login',
      category: 'User',
      label: 'Google Login',
    });

  } catch (error) {
    console.error('Error during login:', error);
  } finally {
    setIsLoggingIn(false);
  }
};

const handleLogout = async () => {
  try {
    await auth.signOut();
    setUser(null);
  } catch (error) {
    console.error('Error during logout:', error);
  }
};


const totalTracks = tracks.length; // Add this line here

const PreviousGeneratedTracksDialog = ({ isOpen, onClose, trackUrl1, trackUrl2, trackName1, trackName2, trackGenre1, trackGenre2, trackInstruments1, trackInstruments2, creationDuration }) => {
  if (!isOpen) return null;

  const shareUrl1 = `${window.location.origin}${trackUrl1}`;
  const shareUrl2 = `${window.location.origin}${trackUrl2}`;
  const shareText1 = `Check out this bhajan: ${trackName1} - Genre: ${trackGenre1} - Instruments: ${trackInstruments1}`;
  const shareText2 = `Check out this bhajan: ${trackName2} - Genre: ${trackGenre2} - Instruments: ${trackInstruments2}`;

  const handleClose = () => {
    onClose();
    window.location.reload(); // Refresh the page on close
  };

  console.log('Track#1', trackUrl1);
  console.log('Track#1', trackName1);
  console.log('Track#1', trackGenre1);    
  console.log('Track#1', trackInstruments1);      
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-red-500 p-6 rounded-lg shadow-lg max-w-md w-full">
        <h3 className="text-2xl font-bold mb-6 text-white">Generated Tracks in {creationDuration} mins</h3>
        <div className="mb-6">
          <p className="text-lg text-white font-semibold">{trackName1}</p>
          <p className="text-white">Genre: {trackGenre1}</p>
          <p className="text-white mb-2">Instruments: {trackInstruments1}</p>
          <audio controls className="w-full mb-4" controlsList="nodownload">
            <source src={trackUrl1} type="audio/mp3" />
            Your browser does not support the audio element.
          </audio>
          <div className="flex items-center space-x-2 mb-4">
            <WhatsappShareButton url={shareUrl1} title={shareText1}>
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
            <FacebookShareButton url={shareUrl1} quote={shareText1}>
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <TwitterShareButton url={shareUrl1} title={shareText1}>
              <TwitterIcon size={32} round />
            </TwitterShareButton>
          </div>
        </div>
        <div className="mb-6">
          <p className="text-lg text-white font-semibold">{trackName2}</p>
          <p className="text-white">Genre: {trackGenre2}</p>
          <p className="text-white mb-2">Instruments: {trackInstruments2}</p>
          <audio controls className="w-full mb-4" controlsList="nodownload">
            <source src={trackUrl2} type="audio/mp3" />
            Your browser does not support the audio element.
          </audio>
          <div className="flex items-center space-x-2 mb-4">
            <WhatsappShareButton url={shareUrl2} title={shareText2}>
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
            <FacebookShareButton url={shareUrl2} quote={shareText2}>
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <TwitterShareButton url={shareUrl2} title={shareText2}>
              <TwitterIcon size={32} round />
            </TwitterShareButton>
          </div>
        </div>
        <button
          onClick={onClose}
          className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 mt-4"
        >
          Close
        </button>
      </div>
    </div>
  );
};

const secondlastGeneratedTracksDialog = ({ isOpen, onClose, trackUrl1, trackUrl2, trackName1, trackName2, trackGenre1, trackGenre2, trackInstruments1, trackInstruments2, creationDuration }) => {
  if (!isOpen) return null;

  const shareUrl1 = `${window.location.origin}${trackUrl1}`;
  const shareUrl2 = `${window.location.origin}${trackUrl2}`;
  const shareText1 = `Check out this bhajan: ${trackName1} - Genre: ${trackGenre1} - Instruments: ${trackInstruments1}`;
  const shareText2 = `Check out this bhajan: ${trackName2} - Genre: ${trackGenre2} - Instruments: ${trackInstruments2}`;

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      window.location.reload(); // Refresh the page on close
    }, 100); // Small delay to ensure state updates before reloading
  };

  const playTrack = (trackUrl) => {
    const audioElement = document.querySelector("audio");
    audioElement.src = trackUrl;
    audioElement.play();
  };

  const openTrackInNewWindow = (trackUrl) => {
    window.open(trackUrl, '_blank');
  };

  console.log('Track#1', trackUrl1);
  console.log('Track#1', trackName1);
  console.log('Track#1', trackGenre1);    
  console.log('Track#1', trackInstruments1);      
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-blue-200 p-6 rounded-lg shadow-lg max-w-md w-full">

        <h3 className="text-2xl font-bold mb-6 text-white">Generated Tracks in {creationDuration} mins</h3>
        <div className="mb-6">
          <p className="text-lg text-white font-semibold">{trackName1}</p>
          <p className="text-white">Genre: {trackGenre1}</p>
          <p className="text-white mb-2">Instruments: {trackInstruments1}</p>

          <button
            onClick={() => openTrackInNewWindow(trackUrl1)}
            className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 mt-4"
          >
            Play Track 1
          </button>
          
          <div className="flex items-center space-x-2 mb-4">
            <WhatsappShareButton url={shareUrl1} title={shareText1}>
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
            <FacebookShareButton url={shareUrl1} quote={shareText1}>
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <TwitterShareButton url={shareUrl1} title={shareText1}>
              <TwitterIcon size={32} round />
            </TwitterShareButton>
          </div>
        </div>
        <div className="mb-6">
          <p className="text-lg text-white font-semibold">{trackName2}</p>
          <p className="text-white">Genre: {trackGenre2}</p>
          <p className="text-white mb-2">Instruments: {trackInstruments2}</p>
          <button
            onClick={() => openTrackInNewWindow(trackUrl2)}
            className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 mt-4"
          >
            Play Track 2
          </button>
          <div className="flex items-center space-x-2 mb-4">
            <WhatsappShareButton url={shareUrl2} title={shareText2}>
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
            <FacebookShareButton url={shareUrl2} quote={shareText2}>
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <TwitterShareButton url={shareUrl2} title={shareText2}>
              <TwitterIcon size={32} round />
            </TwitterShareButton>
          </div>
        </div>
        <button
          onClick={handleClose}
          className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 mt-4"
        >          Close
        </button>
      </div>
    </div>
  );
};

const GeneratedTracksDialog = ({ isOpen, onClose, trackUrl1, trackUrl2, trackName1, trackName2, trackGenre1, trackGenre2, trackInstruments1, trackInstruments2, creationDuration }) => {
  if (!isOpen) return null;

  const shareUrl1 = `${window.location.origin}${trackUrl1}`;
  const shareUrl2 = `${window.location.origin}${trackUrl2}`;
  const shareText1 = `Check out this bhajan: ${trackName1} - Genre: ${trackGenre1} - Instruments: ${trackInstruments1}`;
  const shareText2 = `Check out this bhajan: ${trackName2} - Genre: ${trackGenre2} - Instruments: ${trackInstruments2}`;

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      window.location.reload(); // Refresh the page on close
    }, 100); // Small delay to ensure state updates before reloading
  };

  const openTrackInNewWindow = (trackUrl) => {
    window.open(trackUrl, '_blank');
  };



  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">

        <h3 className="text-2xl font-bold mb-6 text-gray-800">Generated Tracks in {creationDuration} mins</h3>
        <div className="mb-6">
          <p className="text-lg text-gray-800 font-semibold">{trackName1}</p>
          <p className="text-gray-600">Genre: {trackGenre1}</p>
          <p className="text-gray-600 mb-2">Instruments: {trackInstruments1}</p>

          <button
            onClick={() => openTrackInNewWindow(trackUrl1)}
            className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 mt-2 mb-4"
          >
            Play Track 1
          </button>
          
          <div className="flex items-center space-x-2 mb-4">
            <WhatsappShareButton url={shareUrl1} title={shareText1}>
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
            <FacebookShareButton url={shareUrl1} quote={shareText1}>
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <TwitterShareButton url={shareUrl1} title={shareText1}>
              <TwitterIcon size={32} round />
            </TwitterShareButton>
          </div>
        </div>
        <div className="mb-6">
          <p className="text-lg text-gray-800 font-semibold">{trackName2}</p>
          <p className="text-gray-600">Genre: {trackGenre2}</p>
          <p className="text-gray-600 mb-2">Instruments: {trackInstruments2}</p>
          <button
            onClick={() => openTrackInNewWindow(trackUrl2)}
            className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 mt-2 mb-4"
          >
            Play Track 2
          </button>
          <div className="flex items-center space-x-2 mb-4">
            <WhatsappShareButton url={shareUrl2} title={shareText2}>
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
            <FacebookShareButton url={shareUrl2} quote={shareText2}>
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <TwitterShareButton url={shareUrl2} title={shareText2}>
              <TwitterIcon size={32} round />
            </TwitterShareButton>
          </div>
        </div>
        <button
          onClick={handleClose}
          className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 mt-4"
        >
          Close
        </button>
      </div>
    </div>
  );
};


  const handleGenreChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedGenre([...selectedGenre, value]);
    } else {
      setSelectedGenre(selectedGenre.filter((genre) => genre !== value));
    }
  };
  
  const handleInstrumentChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedInstrument([...selectedInstrument, value]);
    } else {
      setSelectedInstrument(selectedInstrument.filter((instrument) => instrument !== value));
    }
  };
  
 
  const handleVocalsChange = (e) => {
    setVocals(e.target.value);
  };
  

  const handleCheckboxChange = (e, setSelectedItems, limit) => {
    const { value, checked } = e.target;
    setSelectedItems(prev => {
      if (checked) {
        if (prev.length < limit) {
          return [...prev, value];
        } else {
          e.target.checked = false;
          return prev;
        }
      } else {
        return prev.filter(item => item !== value);
      }
    });
  };
  

//  const handleLike = async (trackId) => {
//    try {
//        const response = await fetch('/api/like-track', {
//            method: 'POST',
//            headers: { 'Content-Type': 'application/json' },
//            body: JSON.stringify({ trackId })
//        });
//        const data = await response.json();
//        if (data.success) {
//            setTracks(prevTracks => prevTracks.map(track => {
//                if (track.id === trackId) {
//                    return { ...track, likes: track.likes + 1 };
//                }
//                return track;
//            }));
//        } else {
//            console.error('Error liking track:', data.error);
//        }
//    } catch (error) {
//        console.error('Error liking track:', error);
//    }
//};

const handleLike = async (trackId) => {
  try {
    const response = await fetch('/api/like-track', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ trackId })
    });
    const data = await response.json();
    if (data.success) {
      setTracks(prevTracks => prevTracks.map(track => {
        if (track.id === trackId) {
          return { ...track, likes: (track.likes || 0) + 1 };
        }
        return track;
      }));
      // Update the likes state if the liked track is the current track
      if (tracks[currentTrack].id === trackId) {
        setLikes(prevLikes => prevLikes + 1);
      }
    } else {
      console.error('Error liking track:', data.error);
    }
  } catch (error) {
    console.error('Error liking track:', error);
  }
};
  
  useEffect(() => {
    if (tracks.length > 0 && currentTrack < tracks.length) {
        const track = tracks[currentTrack];
        setLikes(track.likes || 0);
        setViews(track.views || 0);
        setTotalPlayedTime(track.total_played_time || 0);
        //setCreatedDate(track.created_at || 'undefined');
    }
}, [currentTrack, tracks]);


  
useEffect(() => {
  setIsLoading(true);
  const timeoutId = setTimeout(() => setIsLoading(false), 5000); // 5 second timeout

  fetch('/api/tracks')
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      console.log('Fetched data:', data);
      if (Array.isArray(data) && data.length > 0) {
        const sortedTracks = data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        const indexedTracks = sortedTracks.map((track, index) => ({ ...track, index }));
        setTracks(indexedTracks);
        setCurrentTrack(0);
        // Initialize likes, views, and total played time for the first track
        setLikes(indexedTracks[0].likes || 0);
        setViews(indexedTracks[0].views || 0);
        setTotalPlayedTime(indexedTracks[0].total_played_time || 0);
      } else {
        console.error('Fetched data is not an array or is empty:', data);
      }
      setIsLoading(false);
    })
    .catch(error => {
      console.error('Error fetching tracks:', error);
      setIsLoading(false);
    });

  return () => clearTimeout(timeoutId);
}, []);

  
  
  //const formattedTrack = tracks.length > 0 ? tracks[currentTrack] : { name: '', artist: '', date: '' };

  const formattedTrack = tracks[currentTrack] || { name: '', artist: '', created_at: '' };



  useEffect(() => {
    if (audioRef.current && tracks.length > 0) {
      const audioUrl = `/audio/${tracks[currentTrack]?.filename}?player=true`;
      console.log(`Setting new audio source: ${audioUrl}`);
      audioRef.current.src = audioUrl;
      audioRef.current.currentTime = 0; // Reset the current time
      if (isPlaying) {
        audioRef.current.play().catch(error => {
          console.error('Error playing audio:', error);
          setPlayAfterInteraction(true); // Set this to true to indicate we want to play after interaction
        });
      }
    }
  }, [currentTrack, tracks]);
  
  




  useEffect(() => {
    if (audioRef.current) {
      console.log(`isPlaying changed: ${isPlaying}`);
      if (isPlaying) {
        console.log(`Attempting to play at currentTime=${audioRef.current.currentTime}`);
        audioRef.current.play().catch(error => {
          console.error('Error playing audio:', error);
          setPlayAfterInteraction(true); // Set this to true to indicate we want to play after interaction
        });
      } else {
        console.log(`Pausing at currentTime=${audioRef.current.currentTime}`);
        audioRef.current.pause();
      }
    }
  }, [isPlaying]);
  
  

  
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const playParam = params.get('play');
    //console.log('Play parameter from URL:', playParam);

    if (playParam && tracks.length > 0) {
      const trackIndex = tracks.findIndex(track => track.filename === `${playParam}.mp3`);
      console.log('trackindex:', trackIndex);
      if (trackIndex !== -1) {
        //console.log(`Setting current track to index: ${trackIndex}`);
        setCurrentTrack(trackIndex);
        setPlayAfterInteraction(true); // Set this to true to indicate we want to play after interaction
        // Set the audio source and play it
        //if (audioRef.current) {
//          audioRef.current.src = `${tracks[trackIndex].filename}`;
          //audioRef.current.play().catch(error => console.error('Error playing audio:', error));
        //}
      } else {
        console.log(`No track found with filename: audio_${playParam}.mp3`);
      }
    }
  }, [location.search, tracks]);
  
// Use effect to listen for user interaction if playAfterInteraction is true
useEffect(() => {
  if (playAfterInteraction) {
    const handleUserInteraction = () => {
      handlePlayClick();
      setPlayAfterInteraction(false); // Reset this state
      document.removeEventListener('click', handleUserInteraction);
    };

    document.addEventListener('click', handleUserInteraction);

    return () => {
      document.removeEventListener('click', handleUserInteraction);
    };
  }
}, [playAfterInteraction]);

const handlePlayClick = () => {
  if (audioRef.current) {
    if (isPlaying) {
      console.log('Pausing audio');
      audioRef.current.pause();
      setIsPlaying(false);
    } else {
      console.log('Playing audio');
      audioRef.current.play().catch(error => {
        console.error('Error playing audio:', error);
        setPlayAfterInteraction(true); // Set this to true to indicate we want to play after interaction
      });
      setIsPlaying(true);

      // Track play event
      GA.event({
        action: 'play_bhajan',
        category: 'Bhajan',
        label: tracks[currentTrack]?.bhajan_name || 'Unknown',
      });


    }
  }
};



const PlayDialog = ({ onClose }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full text-center">
        <h2 className="text-xl font-bold mb-4">Play Bhajan</h2>
        <p className="mb-6">Please click the button below to play the Bhajan.</p>
        <button
          onClick={onClose}
          className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
        >
          Play Bhajan
        </button>
      </div>
    </div>
  );
};



useEffect(() => {
  if (audioRef.current) {
    audioRef.current.addEventListener('timeupdate', handleTimeUpdate);
    console.log(`Adding timeupdate event listener`);
    return () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener('timeupdate', handleTimeUpdate);
        console.log(`Removing timeupdate event listener`);
      }
    };
  }
}, []);


  
const handleSeek = (e) => {
  const time = parseFloat(e.target.value);
  if (audioRef.current) {
    console.log(`Seeking to time: ${time}`);
    audioRef.current.currentTime = time;
    setCurrentTime(time);
  }
  updateSeekBarBackground(time, duration);
};

  

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = isMuted ? 0 : volume / 100;
    }
  }, [volume, isMuted]);
  
  const handleVolumeChange = (e) => {
    const newVolume = parseInt(e.target.value);
    setVolume(newVolume);
    if (audioRef.current) {
      audioRef.current.volume = newVolume / 100;
    }
    setIsMuted(newVolume === 0);
  };
  
  


  const recordPlay = async (trackId, duration) => {
    await fetch('/api/record-play', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ trackId, duration })
    });
};


const filteredTracks = tracks.filter(track => {
  const matchesSearch = searchTerm === '' || 
    (track.bhajan_name && track.bhajan_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
    (track.genre && track.genre.toLowerCase().includes(searchTerm.toLowerCase()));
  const matchesCategory = !selectedCategory || (track.bhajan_name && track.bhajan_name.startsWith(selectedCategory));
  return matchesSearch && matchesCategory;
});

  
const categorizedTracks = filteredTracks.reduce((acc, track, index) => {
  const category = track.bhajan_name.split(' ')[0];
  if (!acc[category]) {
    acc[category] = [];
  }
  acc[category].push({ ...track, index });
  return acc;
}, {});

  
  //useEffect(() => {
  //  console.log('Tracks after updating:', tracks);
  //  console.log('Categorized tracks:', categorizedTracks);
  //}, [tracks, categorizedTracks]);


  const updatePlayedDuration = async (trackId, duration) => {
    await fetch('/api/update-played-duration', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ trackId, duration })
    });
  };

  const handleTimeUpdate = () => {
    if (audioRef.current && !isNaN(audioRef.current.duration)) {
      setCurrentTime(audioRef.current.currentTime);
      setDuration(audioRef.current.duration);
      updateSeekBarBackground(audioRef.current.currentTime, audioRef.current.duration);
      //console.log(`Time update: currentTime=${audioRef.current.currentTime}, duration=${audioRef.current.duration}`);
    }
  };

  const updateSeekBarBackground = (currentTime, duration) => {
    const seekBar = document.getElementById('seek-bar');
    const value = (currentTime / duration) * 100;
    seekBar.style.background = `linear-gradient(to right, orange ${value}%, gray ${value}%)`;
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const toggleFullScreen = () => {
    const playerContainer = document.getElementById('player-container');
    if (!document.fullscreenElement) {
      playerContainer.requestFullscreen().catch(err => console.error(err));
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  };

  const stopPlayer = () => {
    setIsPlaying(false);
    audioRef.current.pause();
    audioRef.current.currentTime = 0;
    setCurrentTime(0);
  };

  const toggleLanguage = () => {
    setLanguage((prevLanguage) => (prevLanguage === 'EN' ? 'HI' : 'EN'));
  };

  const toggleStyle = (style) => {
    setSelectedStyles((prevStyles) =>
      prevStyles.includes(style) ? prevStyles.filter(s => s !== style) : [...prevStyles, style]
    );
  };


 // New component for progress dialog
const ProgressDialog = ({ isOpen, progress, message }) => {
  if (!isOpen) return null;
  return (
    <div className="fixed bottom-4 right-4 bg-gray-800 p-4 rounded-lg shadow-lg z-50 w-96">
      <div className="flex items-center">
        <CircularProgressbar
          value={progress}
          text={`${Math.round(progress)}%`}
          styles={buildStyles({
            textColor: '#ffffff',
            pathColor: '#f97316',
          })}
          className="w-16 h-16"
        />
        <div className="ml-4">
          <p className="text-white font-semibold">{message}</p>
        </div>
      </div>
    </div>
  );
};


  const handleGenerateBhajan = async () => {

    if (!user) {
      alert('You need to be logged in to generate a bhajan.');
      return;
    }

    if (!selectedBhajan || selectedGenre.length === 0 || selectedInstrument.length === 0) {
      alert('Please select a Bhajan, at least one genre, and at least one instrument.');
      return;
  }


  // Track bhajan generation event
  GA.event({
    action: 'generate_bhajan',
    category: 'Bhajan',
    label: selectedBhajan,
  });


 // Check if user has already created a song today
 try {
  const response = await fetch('/api/check-daily-limit', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ userEmail: user.email })
  });
  const data = await response.json();
  if (data.limitReached) {
    // Show stylish quota exceeded message
    setQuotaExceeded(true);
    return;
  }
} catch (error) {
  console.error('Error checking daily limit:', error);
  alert('An error occurred. Please try again.');
  return;
}


    setIsGenerating(true);
    setGenerationProgress(0);
    setGenerationMessage('');
    setIsProgressDialogOpen(true); // Open the progress dialog

    const params = new URLSearchParams({
      bhajanName: selectedBhajan,
      selectedGenre: selectedGenre.join(','),  // Assuming genres are selected as an array
      
      selectedInstrument: selectedInstrument.join(','),  // Assuming instruments are selected as an array
      bpm,
      vocals: selectedVocals,
      userName: user.displayName,
      userEmail: user.email      

    }).toString();
  
    const eventSource = new EventSource(`/api/generate-custom-bhajan?${params}`);
  
    eventSource.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.error) {
        alert(data.error);
        setIsGenerating(false);
        setIsProgressDialogOpen(false); // Close the progress dialog
        eventSource.close();
        return;
      }
  
      setGenerationProgress(data.progress);
      setGenerationMessage(data.message);
  
      if (data.progress === 100) {
        setIsGenerating(false);
        setIsProgressDialogOpen(false); // Close the progress dialog
        setGenerationMessage('Track created successfully');
        // Save the final message and progress to local storage
        //localStorage.setItem('generationMessage', 'Track created successfully');
        setGeneratedTrackUrl(data.trackUrl1);  // Assuming your server returns the generated track URL
        setGeneratedTrackUrl2(data.trackUrl2);  // Assuming your server returns the second generated track URL
        setcreationDuration(data.creationDuration);  
        setGeneratedTrackName1(data.trackName1); // Set the track name for track 1
        setGeneratedTrackName2(data.trackName2); // Set the track name for track 2
        setGeneratedTrackGenre1(data.trackGenre1); // Set the genre for track 1        
        setGeneratedTrackGenre2(data.trackGenre2); // Set the genre for track 2        
        setGeneratedTrackInstruments1(data.trackInstruments1); // Set the instruments for track 1                
        setGeneratedTrackInstruments2(data.trackInstruments2); // Set the instruments for track 2                
        setIsDialogOpen(true);  // Open the dialog

        //localStorage.setItem('generationProgress', '100');
        eventSource.close();
    }
    };
  
    eventSource.onerror = (error) => {
      console.error('EventSource failed:', error);
      setIsGenerating(false);
      setIsProgressDialogOpen(false); // Close the progress dialog
      eventSource.close();
    };
  };
  const handleShare = (track) => {
    const shareUrl = `${window.location.origin}/track/${track.filename}`;
    const shareText = `Check out this bhajan: ${track.bhajan_name} - ${shareUrl}`;

    if (navigator.share) {
        navigator.share({
            title: 'LiveBhajans (admin@livebhajans.com)',
            text: shareText,
            url: shareUrl
        }).catch(error => console.error('Error sharing:', error));
    } else {
        // Fallback for browsers that don't support the Web Share API
        alert('Share not supported on this browser. Copy this link: ' + shareUrl);
    }
};

  
//  useEffect(() => {
//    const savedMessage = localStorage.getItem('generationMessage');
//    const savedProgress = localStorage.getItem('generationProgress');
//
//    if (savedMessage && savedProgress) {
//        setGenerationMessage(savedMessage);
//        setGenerationProgress(Number(savedProgress));
//    }
//}, []);
  
  

  const toggleRepeat = () => {
    setRepeat(!repeat);
    console.log(`Repeat set to: ${!repeat}`);
  };
  

  const toggleShuffle = () => {
    setShuffle(!shuffle);
    console.log(`Shuffle set to: ${!shuffle}`);
  };
  
  const getRandomTrackIndex = () => {
    let randomIndex;
    do {
      randomIndex = Math.floor(Math.random() * tracks.length);
    } while (randomIndex === currentTrack); // Ensure we don't select the same track
    return randomIndex;
  };
  

  const toggleCategory = (category) => {
    setSelectedCategory(selectedCategory === category ? null : category);
  };


  
 
  

const descriptions = {
  EN: `
    <h1>Welcome to LiveBhajans: Your Gateway to Modern Spiritual Music</h1>
    <p>Embark on an <strong>unparalleled spiritual journey</strong> with LiveBhajans! We're thrilled to present a collection of <a href="/unique-bhajans">unique bhajans</a> that will resonate with your soul and elevate your spirit.</p>
    <h2>Experience Divine Fusion:</h2>
    <ul>
      <li>Groove to the <a href="/bhajans/hanuman-chalisa-rap">Hanuman Chalisa in dynamic RAP style</a></li>
      <li>Immerse in the <a href="/bhajans/raghupati-raghav-jazz">Raghupati Raghav Raja Ram with a Jazz twist</a></li>
      <li>Explore our <a href="/fusion-bhajans">vast array of fusion devotional music</a></li>
    </ul>
    <p>Our <strong>divine tunes</strong> are crafted to touch your heart and invigorate your spiritual being. Embrace this <a href="/new-wave-devotion">new wave of devotion</a>, and let your spirit dance to the rhythm of faith.</p>
    <p><strong>Ready to transform your spiritual world?</strong> <a href="/create-bhajan">Create your own custom bhajan</a> or <a href="/popular-bhajans">explore our top tracks</a> now!</p>
  `,
  
  HI: `
    <h1>लाइवभजन्स में आपका स्वागत है: आधुनिक आध्यात्मिक संगीत का द्वार</h1>
    <p>लाइवभजन्स के साथ एक <strong>अद्वितीय आध्यात्मिक यात्रा</strong> पर निकलें! हम आपके लिए <a href="/unique-bhajans">अनोखे भजनों</a> का संग्रह लाए हैं जो आपकी आत्मा को झंकृत करेंगे और आपके मन को ऊँचाइयों पर ले जाएंगे।</p>
    <h2>दिव्य संगम का अनुभव करें:</h2>
    <ul>
      <li><a href="/bhajans/hanuman-chalisa-rap">हनुमान चालीसा को डायनामिक रैप शैली</a> में सुनें</li>
      <li><a href="/bhajans/raghupati-raghav-jazz">रघुपति राघव राजा राम को जैज़ के अंदाज़</a> में अनुभव करें</li>
      <li>हमारे <a href="/fusion-bhajans">फ्यूज़न भक्ति संगीत</a> के विशाल संग्रह की खोज करें</li>
    </ul>
    <p>हमारे <strong>दिव्य स्वर</strong> आपके हृदय को छूने और आपके आध्यात्मिक अस्तित्व को सजीव करने के लिए रचे गए हैं। <a href="/new-wave-devotion">भक्ति की इस नई लहर</a> को अपनाएँ और अपनी आत्मा को विश्वास की धुन पर नृत्य करने दें।</p>
    <p><strong>क्या आप अपने आध्यात्मिक संसार को बदलने के लिए तैयार हैं?</strong> <a href="/create-bhajan">अपना खुद का भजन बनाएं</a> या <a href="/popular-bhajans">हमारे लोकप्रिय गीतों</a> का अन्वेषण करें!</p>
  `
};







  return (
    <ThemeProvider>
    <div className="flex flex-col min-h-screen bg-background">

      {/* Navbar */}
      <nav className="bg-gradient-to-r from-orange-400 to-yellow-300 text-gray-800 p-4 shadow-lg">

  <div className="container mx-auto flex flex-col md:flex-row justify-between items-center">
    <div className="flex items-center space-x-4 mb-4 md:mb-0">
      <img 
        src={tracks[currentTrack]?.cover_image || "/bhajan-cover.jpg"} 
        alt="Create your own awesome bhajans with LiveBhajans" 
        className="w-12 h-12 rounded-full object-cover shadow-md border-2 border-white"
      />
      <h1 className="text-xl md:text-2xl font-bold text-secondary text-center md:text-left">LiveBhajans</h1>
    </div>

    <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-6">
      {user ? (
        <div className="flex flex-col md:flex-row items-center space-y-2 md:space-y-0 md:space-x-4">
          <span className="text-gray-700 text-center md:text-left">
            <span className="font-semibold">Namaste, {user.displayName}</span>
            <span className="text-sm block md:inline md:ml-2">(You have created {userTracks} tracks)</span>
          </span>
          <button 
            onClick={handleLogout} 
            className="bg-red-500 text-white py-2 px-4 rounded-full hover:bg-red-600 transition duration-300 ease-in-out transform hover:scale-105 w-full md:w-auto"
          >
            Logout
          </button>
        </div>
      ) : (
        <button 
          onClick={handleLogin} 
          className="bg-green-500 text-white py-2 px-4 rounded-full hover:bg-green-600 transition duration-300 ease-in-out transform hover:scale-105 w-full md:w-auto"
        >
          {isLoggingIn ? 'Logging in...' : 'Login with Google'}
        </button>
      )}

      <div className="flex flex-col md:flex-row items-center space-y-2 md:space-y-0 md:space-x-4">
        <span className="text-gray-700 font-medium text-center md:text-left">Website Views: {totalViews}</span>
        <button 
          className="text-blue-600 hover:text-blue-800 transition-colors duration-200 bg-white rounded-full p-2 shadow-md hover:shadow-lg"
          onClick={() => setShowInfo(true)}
        >
          <Info size={20} />
        </button>
        <div className="flex items-center space-x-2 bg-white rounded-full px-2 py-1 shadow-md">
          <span className={`text-sm font-medium ${language === 'EN' ? 'text-blue-600' : 'text-gray-500'}`}>EN</span>
          <button 
            onClick={toggleLanguage} 
            className={`w-10 h-5 flex items-center rounded-full p-0.5 duration-300 ease-in-out ${language === 'HI' ? 'bg-blue-600' : 'bg-gray-300'}`}
          >
            <div className={`bg-white w-4 h-4 rounded-full shadow-md transform duration-300 ease-in-out ${language === 'HI' ? 'translate-x-5' : ''}`}></div>
          </button>
          <span className={`text-sm font-medium ${language === 'HI' ? 'text-blue-600' : 'text-gray-500'}`}>HI</span>
        </div>
      </div>
    </div>
  
    <ThemeSwitcher /></div>
</nav>






 {/* Show Info Modal */}
 {showInfo && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
    <div className="bg-white rounded-lg shadow-lg w-full max-w-3xl max-h-full flex flex-col">
      <div className="p-6 overflow-y-auto flex-grow">
        <h3 className="text-2xl font-bold mb-4">Livebhajans Story</h3>
        <p className="mb-4">
        In 2008, a humble soul named Pankaj Pande embarked on a heartfelt mission to share his love for bhajans and spiritual music with the world. This journey began with a small spark of inspiration, a deep desire to connect people with the divine through the power of sacred melodies. Pankaj's life was steeped in the rich traditions of bhajans, and he dreamed of creating a platform where anyone, anywhere, could experience the profound peace and joy that these spiritual songs brought to his own heart.

        For years, Pankaj poured his heart and soul into this endeavor, creating, curating, and sharing bhajans with a growing community of like-minded individuals. His efforts resonated with many, and slowly but surely, his vision began to take shape. However, as life often does, it threw Pankaj some unexpected challenges. The demands of daily life, work, and personal commitments began to overshadow his passion project. With a heavy heart, Pankaj had to set aside his dream, leaving his beloved bhajans in silence.

        Time passed, but the flame of devotion in Pankaj's heart never dimmed. Every bhajan he had shared, every melody he had cherished, continued to echo in his soul. In moments of quiet reflection, he would often think back to his dream, feeling a profound sense of longing and unfinished business. It was during one of these reflective moments that a realization struck him with overwhelming clarity: the world needed the spiritual solace and upliftment that bhajans provide now more than ever.

        Moved by this realization, Pankaj decided to reignite his passion with renewed vigor. He knew that this was more than just a project; it was his calling. With a deep sense of purpose, he began to rebuild what he had started years ago, this time with a clearer vision and an unshakeable resolve. Pankaj's journey was not just about sharing music; it was about creating a sanctuary where souls could find peace, where hearts could be healed, and where the divine could be experienced in the simplest yet most profound way.

        Today, Pankaj Pande's platform stands as a testament to his unwavering dedication and love for bhajans and spiritual music. Each note, each song is a labor of love, a prayer set to music. Through his efforts, countless individuals have found a source of comfort and inspiration. Pankaj's story is a beautiful reminder that dreams rooted in love and devotion never truly fade away; they merely wait for the right moment to blossom once again.

        Join Pankaj on this spiritual journey, and let the timeless melodies of bhajans uplift your soul and fill your heart with divine peace.
        </p>
      </div>
      <div className="p-4 border-t border-gray-200">
        <button
          onClick={() => setShowInfo(false)}
          className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
        >
          Close
        </button>
      </div>
    </div>
  </div>
)}




      {/* Main Content */}
      
      <main className="flex-grow container mx-auto px-4 py-8">
      <div className="border-8 border-double border-orange-500 rounded-lg p-8">
        {/* Website Description */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-12"
        >
          <h2 className="text-4xl font-bold mb-4 text-orange-600">{language === 'EN' ? 'Welcome to LiveBhajans!' : 'लाइव भजन में आपका स्वागत है!'}</h2>
         <div 
  className="text-xl max-w-3xl mx-auto description-content" 
  dangerouslySetInnerHTML={{ __html: descriptions[language] }}
/>
        </motion.div>




            {/* Top Favorite Songs Section */}
            <div className="w-full mb-8">
  <h3 className="text-3xl font-bold mb-4 text-orange-600">Top 5 Favorite Bhajans</h3>
  <div className="relative">
    <div className="flex overflow-x-auto space-x-4 pb-4 top-songs">
      {topSongs.map((song, index) => (
        <div key={song.id} className="flex-shrink-0 w-64 bg-white p-4 rounded-lg shadow-md top-song-item hover-scale fade-in" style={{animationDelay: `${index * 0.1}s`}}>
          <h4 className="text-lg font-semibold">{`${index + 1}. ${song.bhajan_name}`}</h4>
          <p className="text-gray-600 truncate">Duration: {formatTime(song.duration)}</p>
          <p className="text-gray-600 truncate">Likes: {song.likes}</p>
          <button 
            className="mt-2 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-300"
            onClick={() => {
              setCurrentTrack(tracks.findIndex(t => t.id === song.id));
              setIsPlaying(true);
            }}
          >
            Play
          </button>
        </div>
      ))}
    </div>
    <div className="flex justify-center mt-2 top-songs-dots">
      {topSongs.map((_, index) => (
        <span key={index} className="h-2 w-2 bg-gray-300 rounded-full mx-1"></span>
      ))}
    </div>
  </div>
</div>


        <div className="flex flex-col md:flex-row gap-8">






        {playAfterInteraction && (
        <PlayDialog onClose={handlePlayClick} />
      )}








          {/* Create Bhajan Section */}

<div className="md:w-1/2">
{quotaExceeded && <QuotaExceededMessage onClose={() => setQuotaExceeded(false)} />}

              <h1 className="text-3xl font-bold mb-6 text-orange-600">Create Your Own Awesome Bhajans with LiveBhajans</h1>
              <div className="bg-white p-6 rounded-lg shadow-lg space-y-6">
                <div>
                  <label htmlFor="bhajan-select" className="block text-sm font-medium mb-2 text-gray-700">Select a Bhajan</label>
                  <select
                    id="bhajan-select"
                    value={selectedBhajan}
                    onChange={(e) => setSelectedBhajan(e.target.value)}
                    className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-orange-500"
                  >
                    <option value="">Select Bhajan</option>
        <option value="Raghupati_Raghav">Raghupati Raghav Raja Ram</option>
        <option value="Hanuman_Chalisa">Hanuman Chalisa</option>
        <option value="Achyutam_Keshavam_Krishna">Achyutam Keshavam Krishna</option>
        <option value="Madhurashtakam">Madhurashtakam</option>                
        <option value="Shree_Ram_Chandra_Kripal">Shri Ram Chandra Kripalu Bhajman</option>         
        <option value="Om_Jai_Jagdish">Om Jai Jagadish</option>       
        <option value="Govind_Bolo_Gopal_Jai">Govind Bolo Hari Gopal</option>        
        <option value="hey_ram">Hey Ram Hey Ram</option>      
        <option value="maiya_mori">Maiya More Maine he Makhan</option>    
        <option value="sai_baba_mere_ghar">Mere Ghar Ke Aage Sai Baba </option>   
        <option value="bajarang_baan">Bajarang Baan </option>   

                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium mb-2 text-gray-700">Select Genres (max 4)</label>
                  <div className="grid grid-cols-3 gap-2 text-sm">
                    {[
  "Afrobeat", "Ambient", "Bluegrass", "Blues", "Bollywood", "Bossa Nova", "Chillout",
  "Classical", "Country", "Disco", "Drum and Bass", "Dubstep", "EDM", "Electronic",
  "Electro Swing", "Folk", "Fusion", "Funk", "Gospel", "Grunge", "Hip-Hop", "House",
  "Indie", "Jazz", "K-Pop", "Latin", "Lo-fi", "Mariachi", "Metal", "New Age", "Punk",
  "Pop", "Post-Rock", "R&B", "Rap", "Reggae", "Reggaeton", "Rock", "Shoegaze", "Ska",
  "Soca", "Soul", "Swing", "Synthwave", "Techno", "Trance", "Trap", "World", "Zouk"
]
.map((genre) => (
                      <div key={genre}>
                        <input
                          type="checkbox"
                          id={genre}
                          value={genre}
                          checked={selectedGenre.includes(genre)}
                          onChange={(e) => handleGenreChange(e)}
                          disabled={!selectedGenre.includes(genre) && selectedGenre.length >= 4}
                          className="mr-2"
                        />
                        <label htmlFor={genre} className="font-semibold text-gray-700">{genre}</label>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <label className="block text-sm font-medium mb-2 text-gray-700">Select Instruments & Style (max 5)</label>
                  <div className="grid grid-cols-3 gap-2 text-sm">
                    {[
  "Accordion", "Acoustic Guitar", "Bagpipes", "Banjo", "Bass", "Bassoon", "Bongos",
  "Bouzouki", "Cajón", "Cello", "Chhaine", "Charango", "Clarinet", "Congas", "Didgeridoo",
  "Dulcimer", "Drums", "Dhol", "Damru", "Punjabi Dholak", "Electric Guitar", "Erhu", "Flute", "Guitar", "Gharha", "Harp",
  "Harmonium", "Hook", "Iktara", "Kalimba", "Keyboard", "Mandocello", "Mandolin", "Marimba", "Matka",
  "Oboe", "Oud", "Piano", "Rhythmic Beats", "Rhythmically Intricate", "Saxophone",
  "Saz", "Snare", "Somber Mood", "Steel Drum", "Sapp", "Sarangi", "Synth", "Tabla", "Tambourine",
  "Theremin", "Timbre", "Tumbi", "Trombone", "Trumpet", "Tuba", "Ukulele", "Violin", "Zither",
  "Arpeggio", "Bass Line", "Chord Progression", "Counterpoint", "Dynamics",
  "Harmony", "Melodic Line", "Modulation", "Orchestration", "Polyrhythm",
  "Syncopation", "Tempo", "Tonal Quality"
]
.map((instrument) => (
                      <div key={instrument}>
                        <input
                          type="checkbox"
                          id={instrument}
                          value={instrument}
                          checked={selectedInstrument.includes(instrument)}
                          onChange={(e) => handleInstrumentChange(e)}
                          disabled={!selectedInstrument.includes(instrument) && selectedInstrument.length >= 5}
                          className="mr-2"
                        />
                        <label htmlFor={instrument} className="font-semibold text-gray-700">{instrument}</label>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <label htmlFor="bpm-slider" className="block text-sm font-medium mb-2 text-gray-700">BPM</label>
                  <input
                    id="bpm-slider"
                    type="range"
                    min="60"
                    max="150"
                    value={bpm}
                    onChange={(e) => setBpm(e.target.value)}
                    className="w-full"
                  />
                  <div className="text-center font-semibold text-gray-700">{bpm} BPM</div>
                </div>
                <div>
                  <label htmlFor="vocals-select" className="block text-sm font-medium mb-2 text-gray-700">Vocals</label>
                  <select
                    id="vocals-select"
                    value={selectedVocals}
                    onChange={handleVocalsChange}
                    className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-orange-500"
                  >
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Male + Female">Male + Female</option>
                  </select>
                </div>


                {quotaExceeded && (
  <p className="text-red-500 text-sm mt-2">
    You've reached your daily limit. Please try again tomorrow!
  </p>
)}

                <button
  onClick={handleGenerateBhajan}
  disabled={isGenerating || !selectedBhajan || selectedGenre.length === 0 || selectedInstrument.length === 0 || quotaExceeded}
  className={`w-full py-3 px-6 rounded-lg shadow transition duration-300 font-bold ripple hover-scale ${
    quotaExceeded 
      ? 'bg-gray-500 text-white cursor-not-allowed' 
      : 'bg-pink-500 text-white hover:bg-pink-600'
  } ${isGenerating ? 'opacity-50' : ''}`}
>
  {isGenerating ? 'Generating...' : quotaExceeded ? 'Daily Quota Reached' : 'Generate Custom Bhajan'}
</button>
              </div>
              {isGenerating && (
    <div className="mt-6 flex flex-col items-center">
      <CircularProgressbar
        value={generationProgress}
        text={`${Math.round(generationProgress)}%`}
        styles={buildStyles({
          textColor: '#f97316',
          pathColor: '#f97316',
        })}
        className="w-32 h-32"
      />
      <p className="mt-4 text-orange-600 font-semibold">{generationMessage}</p>
    </div>
  )}
            </div>


            <ProgressDialog isOpen={isProgressDialogOpen} progress={generationProgress} message={generationMessage} />















          {/* Player Section */}
          {isLoading ? (
  <div className="flex items-center justify-center h-64">
    <Loader2 className="animate-spin" size={48} />
  </div>
) : (
          <div className="md:w-1/2">





<div id="player-container" className="bg-background rounded-lg shadow-lg p-4 border-4 border-primary relative player-container">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-2xl font-bold text-orange-600">LiveBhajans Player</h2>
                <div className="flex space-x-3">
                  <Maximize2 size={24} className="text-gray-600 cursor-pointer" onClick={toggleFullScreen} />
                  <ListMusic size={24} className="text-gray-600 cursor-pointer" onClick={() => setShowTracks(!showTracks)} />
                  <Repeat
                    size={24}
                    className={`cursor-pointer ${repeat ? 'text-orange-500' : 'text-gray-600'}`}
                    onClick={toggleRepeat}
                  />
                  <Shuffle
                    size={24}
                    className={`cursor-pointer ${shuffle ? 'text-orange-500' : 'text-gray-600'}`}
                    onClick={toggleShuffle}
                  />
                </div>
              </div>
              

              <div className="player-content flex flex-col md:flex-row items-center md:items-start">
    <img 
        src={tracks[currentTrack]?.cover_image || "/bhajan-cover.jpg"} 
        alt="Create your own awesome bhajans with LiveBhajans" 
        className="player-image w-24 h-24 md:w-32 md:h-32 object-cover rounded-lg mr-0 md:mr-6 mb-4 md:mb-0"
        />
 <div className="player-info text-center md:text-left">
        <h3 className="text-xl font-semibold">
        {formattedTrack.index + 1}. {formatBhajanName(formattedTrack.bhajan_name)}
        </h3>
        <p className="text-lg text-gray-600">
            Genre: {formattedTrack.genre}
        </p>
        <span className="text-sm text-gray-600 block">          Created By: {formattedTrack.user_name} ({maskEmail(formattedTrack.user_email)})
        </span>
        
        <span className="text-sm text-gray-600 block">
          Created: {formattedTrack.created_at ? new Date(formattedTrack.created_at).toLocaleString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
          }) : 'N/A'}
        </span>
        <div className="flex items-center space-x-2 mt-2">
      <span title="Total Likes for this Track">{formattedTrack.likes} <span title="Total Likes for this Track" role="img" aria-label="likes" className="cursor-pointer" onClick={() => handleLike(formattedTrack.id)}>❤️</span></span>
      <span title="Total Track Views">{formattedTrack.views} <span role="img" aria-label="views" title="Total Track Views">👁️</span></span>
      <span title="Total time track has been played">{formatDuration(formattedTrack.total_played_time)}<span title="Total time track has been played" role="img" aria-label="views">⌛</span></span>
    </div>
  </div>
</div>

{/* Add the AudioVisualizer here 
{!isMobile() && <AudioVisualizer audioRef={audioRef} />}

*/}


              
              <div className="mb-6">
                <input
                  id="seek-bar"
                  type="range"
                  min={0}
                  max={duration}   
                  value={currentTime}
                  onChange={handleSeek}
                  className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                  style={{
                    background: `linear-gradient(to right, #f97316 ${(currentTime / duration) * 100}%, #e5e7eb ${(currentTime / duration) * 100}%)`
                  }}
                />
                <div className="flex justify-between text-sm mt-2">
                  <span>{formatTime(currentTime)}</span>
                  <span>{formatTime(duration)}</span>
                </div>
              </div>
              
              <div className="flex justify-between items-center mb-6">
  <SkipBack size={32} className="text-gray-600 cursor-pointer hover-scale" onClick={handleSkipBack}  />
  <div className="w-16 h-16 bg-primary rounded-full flex items-center justify-center cursor-pointer hover-scale ripple" onClick={() => setIsPlaying(!isPlaying)}>
    {isPlaying ? (
      <Pause size={32} className="text-grey" />
    ) : ( 
      <Play size={32} className="text-grey ml-1" />
    )}
  </div>
  <SkipForward size={32} className="text-gray-600 cursor-pointer hover-scale" onClick={handleSkipForward}  />
</div>

              
              <div className="flex items-center space-x-3 mb-6">
                {isMuted ? (
                  <VolumeX size={24} className="cursor-pointer" onClick={() => setIsMuted(false)} />
                ) : (
                  <Volume2 size={24} className="cursor-pointer" onClick={() => setIsMuted(true)} />
                )}
                <input
                  type="range"
                  min="0"
                  max="100"
                  value={isMuted ? 0 : volume}
                  onChange={handleVolumeChange}
                  className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                  style={{
                    background: `linear-gradient(to right, #f97316 ${isMuted ? 0 : volume}%, #e5e7eb ${isMuted ? 0 : volume}%)`
                  }}
                />
              </div>
              
             
              
              <div className="mb-6">
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Search tracks..."
                    className="w-full p-3 pl-10 rounded-lg bg-gray-100 text-gray-900"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <Search className="absolute left-3 top-3 text-gray-600" size={20} />
                </div>
              </div>

              {/* Category Cloud */}
              <div className="mb-6">
                <h4 className="text-xl font-semibold mb-2">Categories (Total Tracks: {totalTracks})</h4>
                <div className="categories flex flex-wrap gap-2">
                 
                  {Object.keys(categorizedTracks).map(category => (
                    <button 
  key={category} 
  className="category-button px-4 py-2 rounded-full border bg-gray-200 text-gray-800 hover:bg-primary hover:text-white transition-colors duration-200 hover-scale ripple"
  onClick={() => toggleCategory(category)}
>
  {category}
  {selectedCategory === category && (
    <X size={16} className="ml-2" />
  )}
</button>
                  ))}
                </div>
              </div>

              {showTracks && (
  <div className="max-h-96 overflow-y-auto" id="playlist-container">
    <div className="track-list space-y-2">
      {filteredTracks.map((track) => (
        <div
  key={track.id}
  ref={(el) => (scrollRefs.current[track.index] = el)}
  className={`flex flex-col md:flex-row items-start md:items-center justify-between py-2 px-4 hover:bg-gray-100 rounded cursor-pointer ${currentTrack === track.index ? 'bg-green-100' : ''} slide-in`}
  style={{animationDelay: `${track.index * 0.05}s`}}
  onClick={() => {
    setCurrentTrack(track.index);
    setIsPlaying(true);
  }}
>
          <div className="flex items-center space-x-2">
            <span>{track.index + 1}</span>
            <div>
              <p className="font-medium">{formatBhajanName(track.bhajan_name)}</p>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  toggleTrackInfo(track.index);
                }}
                style={{
                  backgroundColor: 'blue',
                  color: 'white',
                  border: 'none',
                  padding: '5px 10px',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  marginTop: '5px',
                  display: 'inline-block',
                }}
              >
                {expandedTracks[track.index] ? 'Hide Info' : 'Track Info'}
              </button>
              {expandedTracks[track.index] && (
                <div>
                  <p className="text-sm text-gray-600">Genre: {track.genre}</p>
                  <p className="text-sm text-gray-600">Instruments: {track.instruments}</p>
                  <p className="text-sm text-gray-600">Vocals: {track.vocals}</p>
                  <p className="text-sm text-gray-600">BPM: {track.bpm}</p>
                  <p className="text-sm text-gray-600">
                    Created By: {track.user_name} ({maskEmail(track.user_email)})
                  </p>
                  <span className="text-sm text-gray-600 block">
                    Created Date: {track.created_at ? new Date(track.created_at).toLocaleString('en-US', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit'
                    }) : 'N/A'}
                  </span>
                  <span className="text-sm text-gray-600 flex items-center">
                    <WhatsappShareButton url={`${window.location.origin}/audio/${track.filename}`} className="mr-2">
                      <WhatsappIcon size={32} round />
                    </WhatsappShareButton>
                    <FacebookShareButton url={`${window.location.origin}/audio/${track.filename}`} className="mr-2">
                      <FacebookIcon size={32} round />
                    </FacebookShareButton>
                    <TwitterShareButton url={`${window.location.origin}/audio/${track.filename}`}>
                      <TwitterIcon size={32} round />
                    </TwitterShareButton>
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center space-x-2">
            <span title="Total Likes for this Track">{Math.round(track.likes)} <span title="Total Likes for this Track" role="img" aria-label="likes" className="cursor-pointer" onClick={() => handleLike(track.id)}>❤️</span></span>
            <span title="Total Track Views">{Math.round(track.views)} <span title="Total Track Views" role="img" aria-label="views">👁️</span></span>
            <span title="Total time track has been played">{formatTime(Math.round(track.total_played_time))}<span title="Total time track has been played" role="img" aria-label="views">⌛</span></span>
            <span>{formatTime(track.duration || 0)}</span>
            {currentTrack === track.index && isPlaying ? (
              <Pause size={20} className="text-orange-500 cursor-pointer" onClick={(e) => {
                e.stopPropagation();
                setIsPlaying(false);
              }} />
            ) : (
              <Play size={20} className="text-gray-600 cursor-pointer" onClick={(e) => {
                e.stopPropagation();
                setCurrentTrack(track.index);
                setIsPlaying(true);
              }} />
            )}
          </div>
        </div>
      ))}
    </div>
  </div>
)}









            </div>
          </div>

)}

        </div>
        </div></main>

      {/* Footer */}
      <footer className="bg-gradient-to-r from-orange-500 to-yellow-500 text-black p-4 mt-8">
        <div className="container mx-auto text-center">
          <p>&copy; 2024 LiveBhajans. All rights reserved. Elevate your spiritual journey with our unique bhajans!</p>
        </div>
      </footer>



      <GeneratedTracksDialog 
  isOpen={isDialogOpen}
  onClose={() => setIsDialogOpen(false)}
  trackUrl1={generatedTrackUrl}
  trackUrl2={generatedTrackUrl2}
  trackName1={generatedTrackName1}
  trackName2={generatedTrackName2}
  trackGenre1={generatedTrackGenre1}
  trackGenre2={generatedTrackGenre2}
  trackInstruments1={generatedTrackInstruments1}
  trackInstruments2={generatedTrackInstruments2}
  creationDuration={creationDuration}
/>




      <audio
  ref={audioRef}
  onTimeUpdate={handleTimeUpdate}
  onLoadedMetadata={handleTimeUpdate}
  onEnded={async () => {
    const trackId = tracks[currentTrack]?.id;
    const trackDuration = tracks[currentTrack]?.duration;
    if (trackId && trackDuration) {
        await updatePlayedDuration(trackId, trackDuration);
        setViews(views + 1);
    }
    if (repeat) {
        audioRef.current.currentTime = 0;
        setTimeout(() => {
            audioRef.current.play().catch(error => {
                console.error('Error restarting audio:', error);
            });
        }, 0);
    } else if (shuffle) {
        const nextTrackIndex = getRandomTrackIndex();
        setCurrentTrack(nextTrackIndex);
    } else {
        setCurrentTrack(prev => (prev + 1) % filteredTracks.length);
        setIsPlaying(true); // Ensure playing state is set to true
        setTimeout(() => {
          audioRef.current.play().catch(error => {
            console.error('Error starting audio:', error);
          });
        }, 0);        
    }
}}

  onPause={() => {
    setIsPlaying(false);
    setCurrentTime(audioRef.current.currentTime);
  }}
  onPlay={async () => {
    setIsPlaying(true);
    const trackId = tracks[currentTrack]?.id;
    const trackDuration = tracks[currentTrack]?.duration;
    if (trackId && trackDuration) {
        await recordPlay(trackId, trackDuration);
        setViews(views + 1);
    }
}}
src={tracks[currentTrack]?.filename ? `/audio/${tracks[currentTrack]?.filename}` : ''}
/>











    </div>
    </ThemeProvider>
  );




  
};

export default BhajansWebsite;
